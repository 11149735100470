import { observer } from "mobx-react";
import Logo from "../assets/logo.svg";

const NavBar = observer(() => {
  return (
    <div style={{ width: "100%" }}>
      <div className="main-navbar">
        <img alt="jfinchain" src={Logo} style={{ width: "150px" }} />
      </div>
    </div>
  );
});

export default NavBar;
