import { Divider } from "antd";
import { observer } from "mobx-react";
import { Switch, Route } from "react-router-dom";
import HomePage from "src/pages/HomePage";

import BlockInfo from "./BlockInfo";
import NavBar from "./NavBar";

const Header = observer(() => {
  return (
    <div style={{ width: "100%" }}>
      <NavBar />
      <div style={{ padding: "0 20px 0" }}>
        <Switch>
          <Route component={HomePage} path="/" />
        </Switch>
      </div>
    </div>
  );
});

export default Header;
